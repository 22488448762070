@font-face{
    font-family:"TT Commons";
    src:url("../fonts/tt_commons/Fonts/17009842-ac84-4271-bdeb-38a10ec82592.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/ad28b01a-1f06-469f-8f07-e32d06292237.woff") format("woff");
    font-weight: 250;
    font-style: normal;
  }
  @font-face{
    font-family:"TT Commons";
    src:url("../fonts/tt_commons/Fonts/bb91ecb7-665e-4b32-a8a3-1df1a01b6c07.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/b8497d3a-303d-410f-b147-bb1380590716.woff") format("woff");
    font-weight: 250;
    font-style: italic;
  }
  @font-face{
    font-family:"TT Commons";
    src:url("../fonts/tt_commons/Fonts/58980974-5ea5-4642-baf1-635a99d450d8.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/7f42b6b3-1524-42bd-acb0-5e506ab664af.woff") format("woff");
    font-weight: 265;
    font-style: normal;
  }
  @font-face{
    font-family:"TT Commons";
    src:url("../fonts/tt_commons/Fonts/adcd6b1f-3ea8-49a7-b4d6-b4a9ca96bd24.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/7033c3f9-a861-47f9-8508-a77c1bd9a50c.woff") format("woff");
    font-weight: 265;
    font-style: italic;
  }
  @font-face{
    font-family:"TT Commons";
    src:url("../fonts/tt_commons/Fonts/7f92c560-e510-46cd-99b9-b9bf2986496f.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/730dab86-0b8f-4bda-83f6-d0b77cd67e0f.woff") format("woff");
    font-weight: 300;
    font-style: normal;
  }
  @font-face{
    font-family:"TT Commons";
    src:url("../fonts/tt_commons/Fonts/c56c888d-af3f-4f89-aa8f-95eeff4274d9.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/c7f7227a-47e4-4460-87aa-6cad85fd8c93.woff") format("woff");
    font-weight: 300;
    font-style: italic;
  }
  @font-face{
    font-family:"TT Commons";
    src:url("../fonts/tt_commons/Fonts/542ea8aa-a893-492c-8f57-fb8bbd4e4c50.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/cb278cac-c203-4fd3-8365-c6e73b4c10b3.woff") format("woff");
    font-weight: 350;
    font-style: normal;
  }
  @font-face{
    font-family:"TT Commons";
    src:url("../fonts/tt_commons/Fonts/d7efe636-4e21-4ded-aeec-db7d3622b4a3.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/87be5aaf-7b6a-4c6d-8c61-f70f075c00f3.woff") format("woff");
    font-weight: 350;
    font-style: italic;
  }
  @font-face{
    font-family:"TT Commons";
    src:url("../fonts/tt_commons/Fonts/d07e46ea-8260-4883-bfa3-3a557cb93598.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/91318483-1660-41d7-be20-a7fedf82ee86.woff") format("woff");
    font-weight: 400;
    font-style: normal;
  }
  @font-face{
    font-family:"TT Commons";
    src:url("../fonts/tt_commons/Fonts/c753ccc5-37c2-4d1a-ac4d-9b1878ad732c.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/ca0fc60d-2c5a-47a7-a4a2-4dabb0eacc64.woff") format("woff");
    font-weight: 400;
    font-style: italic;
  }
  @font-face{
    font-family:"TT Commons";
    src:url("../fonts/tt_commons/Fonts/43babb0e-6086-481c-a47c-bf8d35474219.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/50605419-ed6b-469a-bd38-50497cc71558.woff") format("woff");
    font-weight: 500;
    font-style: normal;
  }
  @font-face{
    font-family:"TT Commons";
    src:url("../fonts/tt_commons/Fonts/3b7fd647-f2ef-4e3a-b987-e161805ab603.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/74da044c-bcde-464b-b17d-61be728abb86.woff") format("woff");
    font-weight: 500;
    font-style: italic;
  }
  @font-face{
    font-family:"TT Commons";
    src:url("../fonts/tt_commons/Fonts/14028c16-9f95-49e1-a346-a3ff8f3c63f5.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/8990729d-b642-4528-97e2-11c0c1ea297e.woff") format("woff");
    font-weight: 600;
    font-style: normal;
  }
  @font-face{
    font-family:"TT Commons";
    src:url("../fonts/tt_commons/Fonts/cc145d49-4593-466c-87c8-d8ff7ec6d6b8.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/bb8922e4-8c09-4dba-9739-a46a1ef4b23c.woff") format("woff");
    font-weight: 600;
    font-style: italic;
  }
  @font-face{
    font-family:"TT Commons";
    src:url("../fonts/tt_commons/Fonts/6004dfed-29e5-4130-a90d-0de554ad3f2a.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/25727e6e-2d59-480c-9330-b256fa239b66.woff") format("woff");
    font-weight: 700;
    font-style: normal;
  }
  @font-face{
    font-family:"TT Commons";
    src:url("../fonts/tt_commons/Fonts/5c84984d-948d-4eb3-9a38-de90dff7a6d8.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/46879fd3-6c07-4616-96f4-51f868357c48.woff") format("woff");
    font-weight: 700;
    font-style: italic;
  }
  @font-face{
    font-family:"TT Commons";
    src:url("../fonts/tt_commons/Fonts/eeb09fee-c781-414a-adc4-7fa0c214d948.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/f96ec35e-6ead-41d9-8086-464ad0d315fe.woff") format("woff");
    font-weight: 800;
    font-style: normal;
  }
  @font-face{
    font-family:"TT Commons";
    src:url("../fonts/tt_commons/Fonts/2852248e-9d88-4839-8594-c05b763edd32.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/15073744-9cf2-47b9-b4ca-1bbf8d55a8ae.woff") format("woff");
    font-weight: 800;
    font-style: italic;
  }
  @font-face{
    font-family:"TT Commons";
    src:url("../fonts/tt_commons/Fonts/3a56ec57-7941-4720-992a-8c1c194a3806.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/8db4ca93-552b-4f68-8b06-fd7bb926173d.woff") format("woff");
    font-weight: 900;
    font-style: normal;
  }
  @font-face{
    font-family:"TT Commons";
    src:url("../fonts/tt_commons/Fonts/f15f45ae-80cd-4240-8960-20f2150654d1.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/36356599-eec5-40cd-ad1a-cbce9b44389b.woff") format("woff");
    font-weight: 900;
    font-style: italic;
  }

  @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500, 600, 700');

@font-face{
  font-family:"Hind";
  src:url("../fonts/Hind/Hind-Light.ttf")  format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face{
  font-family:"Hind";
  src:url("../fonts/Hind/Hind-Regular.ttf")  format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face{
  font-family:"Hind";
  src:url("../fonts/Hind/Hind-Medium.ttf")  format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face{
  font-family:"Hind";
  src:url("../fonts/Hind/Hind-SemiBold.ttf")  format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face{
  font-family:"Hind";
  src:url("../fonts/Hind/Hind-Bold.ttf")  format('truetype');
  font-weight: 700;
  font-style: normal;
}

  label{
    font-family: "TT Commons";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  
  h1{
    font-family: "TT Commons";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; 
  }

  h2{
    font-family: "TT Commons";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 116.667% */
    letter-spacing: 0.4px;
  }

  h4, .h4{
    font-family: "TT Commons";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 116.667% */
    letter-spacing: 0.4px;
  }

  h6, .h6{
    font-family: "TT Commons";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.4px;
  }

  a{
    text-decoration: none;
  }