.aitutor-page {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
}

.AI-sidebar {
  background: #e8eefa;
  width:320px;
  flex-shrink:0;
  padding: 1rem 1.5rem;
  justify-content: center;
  max-height: calc(100vh - 64px);
}

.AI-sidebar .expand-button svg{
  width:24px;
  height:24px;
  margin-top:3px;
}

.AI-sidebar .expand-button[aria-expanded="true"] svg{
  transform:rotate(180deg)
}

h1.Ai-title {
  color: #60646d;
  font-family: "TT Commons";
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.24px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

/***ai main */
.AI-main {
  background: #fff;
  /* padding: 1rem 1.5rem 2rem 1.5rem; */
  max-height: calc(100vh - 64px);
  overflow-y:auto;
  overflow-x:hidden;
  width:100%;
  /* border:1px solid purple; */
  position: relative;
}
.AI-main .chatbot-wrapper {
  display: flex;
  flex-direction: row;
 align-items: start;  
 padding:24px 24px 32px;
 overflow: hidden;

}
.AI-main .chatbot-wrapper .chatbot2 {
 width: 3rem;
 height: 3rem;
}
.AI-main .chatbot-wrapper .chatbot-text {
  border-radius: 8px;
  border: 1px solid #dde3ee;
  background-color: #f7f9fd;
  padding: 0.5rem 1rem;
  margin-left: 0.75rem;
  width: 100%;
}
.AI-main .chatbot-wrapper .chatbot-text h2 {
  color: #020917;
  font-family: "TT Commons";
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  padding: 0;
  margin: 0;
}

/**chatbot answer after type the question*/
ul.explain{
  color: var(--Text-lightTheme--Default, #020917);
  font-family: "TT Commons";
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5rem; 
  letter-spacing: 0.015rem;
}
ul.explain li {
  list-style-type: disc;
  list-style-position: inside;
  text-indent: 2rem;

}



.solved{
  border-top: 1px solid #DDE3EE;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:center;
  position:sticky;
  bottom:0;
  background-color:white;
  width:100%;
  /* border:1px solid red; */
  padding: 0 24px;
}

.solved .row{
    display: flex;
    flex-direction: row;
  
  }
  .solved  p{
    color: var(--primary-indigo, #05112A);
  font-family: "TT Commons";
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5rem; 
  letter-spacing: 0.015rem;
  }
  .solved img{
    margin-left:1rem;
  
  }
  .solved .thumb-down{
     transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);


  }
