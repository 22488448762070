:root {
  background-color: #020917;
  color:white;
  font-family: 'TT Commons', 'Open Sans', sans-serif;
  font-size: 18px;
}

*{
  box-sizing: border-box;
 }

 ul{
  margin: 0;
  margin-block-start: 0;
  padding-inline-start: 0;
  margin-block-end: 0;
 }

 li{
  list-style-type: none;
 }

 body{
  background-color: #05112a;
 }

 button{
  border:none;
  background-color:transparent;
  cursor:pointer;
 }

 .pill{
  display: flex;
  border-radius: 64px;
  padding: 11px 14px;
  font-family: Hind;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fefefe;
  background: #ffffff14;
  align-items: center;
  gap: 8px;
  /* transition: background .25s; */
  outline: none;
  height: 40px;
}

.pill:not(.votebtn):hover{
  background-color:#182849;
}

.custom-tooltip{
  background-color: #FEFEFE !important;
  color: #020917 !important;
  font-family: "TT Commons" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  z-index: 2000 !important;
}

.custom-tooltip-dark{
  background-color: #161C29 !important;
  color: #FEFEFE !important;
  font-family: "TT Commons" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  z-index: 2000 !important;
}

.summary-tooltip{
  width:200px !important;
  font-weight:400 !important;
  text-align:center;
}